<template>
  <div class="invoice-view">
    <a-card title="发票抬头" class="page-card">
      <div class="card-content">
        <div class="card-content-item">发票抬头：{{ invoiceForm.name }}</div>
        <div class="card-content-item">统一社会信用代码：{{ invoiceForm.code }}</div>
        <div class="card-content-item">默认发票类型：{{ invoiceForm.baseType }}</div>
        <div class="card-content-item">注册固定电话：{{ invoiceForm.tel }}</div>
        <div class="card-content-item">开户行：{{ invoiceForm.bankName }}</div>
        <div class="card-content-item">基本开户账号：{{ invoiceForm.bankCard }}</div>
        <div class="card-content-item">注册地址：{{ invoiceForm.address }}</div>
        <a-button class="click-btn" size="small" type="primary" @click="handleUpdateInvoice" v-perms="'update'">编辑</a-button>
      </div>
    </a-card>
    <div class="invoice-view-content">
      <a-form-model layout="inline" :model="form">
        <!-- <a-form-model-item label="订单编号">
          <a-input v-model="form.orderNo" placeholder="订单编号" />
        </a-form-model-item> -->
        <a-form-model-item label="类型">
          <a-select :options="typeOptions" style="width: 220px" v-model="form.type" allowClear placeholder="类型">
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="申请取票时间">
          <a-range-picker value-format="YYYY-MM-DD" v-model="form.createTime" allowClear />
        </a-form-model-item>
        <a-form-model-item label="发票状态">
          <a-select
            :options="statusOptions"
            style="width: 220px"
            v-model="form.invoicingStatus"
            allowClear
            placeholder="发票状态"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item style="float: right; margin-right: 0" v-perms="'search'">
          <a-button type="" @click="form = {}"> 重置 </a-button>
          <a-button type="primary" @click="$refs.table.refresh()"> 搜索 </a-button>
        </a-form-model-item>
      </a-form-model>
      <div style="margin-top: 20px">
        <p-table
          ref="table"
          row-key="id"
          extraHeight="530"
          :sourceData="getDataApi"
          download
          :columns="columns">
          <div slot="headerLeft" class="table-header-left">
            <a-button type="primary" @click="handleAdd" v-perms="'add'">申请开票</a-button>
          </div>
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="handleDetails(record)" v-perms="'details'">详情</a-button>
            <a-button type="link" @click="handlePreview(record)" v-if="record.invoicingStatus == 1" v-perms="'look'">预览发票</a-button>
            <a-button type="link" @click="handleDown(record)" v-if="record.invoicingStatus == 1" v-perms="'download-fp'">下载发票</a-button>
          </template>
        </p-table>
      </div>
    </div>
    <detailsItem ref="details" />
    <invoiceForm ref="invoiceForm" @success="getInvoice" />
    <addForm :invoiceHeaderId="invoiceForm.id || ''" ref="addForm" @success="$refs.table.refresh()" />
  </div>
</template>

<script>
import { typeOptions, statusOptions } from './optionType'
import detailsItem from './details.vue'
import invoiceForm from './form.vue'
import addForm from './add.vue'
import { getInvoice, getDataPage, getDetails } from '@/api/invoice'
import moment from 'moment'

export default {
  name: 'Invoice',
  components: { detailsItem, invoiceForm, addForm },
  data () {
    return {
      typeOptions,
      statusOptions,
      form: {},
      invoiceForm: {},
      columns: [
        {
          title: '订单编号',
          dataIndex: 'orderNumber',
          key: 'orderNumber',
          width: 180,
          download: true
        },
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          width: 400,
          download: true
        },
        {
          title: '类别',
          dataIndex: 'type',
          key: 'type',
          // customRender: (t) => {
          //   return this.typeOptions.find((item) => item.value == t).label
          // },
          width: 80,
          download: true
        },
        {
          title: '创建账号',
          dataIndex: 'createBy',
          key: 'createBy',
          width: 100,
          download: true
        },
        {
          title: '发票金额（￥）',
          dataIndex: 'amount',
          key: 'amount',
          width: 160,
          customRender: (t, r) => {
            if (r.type == '会员') {
              return t
            }
            return Math.round(r.totalSum * 100) / 100
          },
          download: true
        },
        {
          title: '申请取票时间',
          dataIndex: 'createTime',
          key: 'createTime',
          customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss'),
          download: true
        },
        {
          title: '发票状态',
          dataIndex: 'invoicingStatus',
          key: 'invoicingStatus',
          customRender: (t) => {
            return this.statusOptions.find((item) => item.value == t).label
          },
          download: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 320,
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.getInvoice()
  },
  methods: {
    getInvoice () {
      getInvoice().then((res) => {
        this.invoiceForm = res.data
      })
    },
    getDataApi (params) {
      const formSearch = this.form
      return getDataPage({
        ...params,
        enterpriseId: this.$store.getters.enterpriseId,
        ...formSearch,
        createTime: (formSearch.createTime || []).join(',')
      })
    },
    handleAdd () {
      this.$refs.addForm.show()
    },
    handleDetails (rowItem) {
      this.$refs.details.show(rowItem)
    },
    handlePreview (rowItem) {
      window.open(rowItem.url)
    },
    handleDown ({ url, name }) {
      const urlArr = url.split('.')
      const fileType = urlArr[urlArr.length - 1].toLowerCase()
      this.$spin.show()
      try {
        var blobType
        switch (fileType) {
          case 'png':
            blobType = 'image/png'
            break
          case 'jpg':
            blobType = 'image/jpg'
            break
          case 'pdf':
            blobType = 'application/pdf'
            break
          default:
            window.open(url)
        }
        if (blobType) {
          this.axios({
            method: 'get',
            url: url,
            responseType: 'blob'
          })
            .then((res) => {
              const blob = new Blob([res], {
                type: blobType
              })
              const a = document.createElement('a')
              const URL = window.URL || window.webkitURL
              const href = URL.createObjectURL(blob)
              a.href = href
              const type = `.${fileType.toLowerCase()}`
              a.download = name.replace(type, '') + type
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              window.URL.revokeObjectURL(href)
            })
            .finally(() => {
              this.$spin.hide()
            })
        } else {
          this.$spin.hide()
        }
      } catch (e) {
        this.$spin.hide()
      }
    },
    handleUpdateInvoice () {
      this.$refs.invoiceForm.open(this.invoiceForm)
    }
  }
}
</script>

<style lang="less">
.invoice-view {
  height: 100%;
  .page-card {
    border-radius: 2px;
    .ant-card-head-title {
      padding: 12px 0;
    }
    .ant-card-body {
      padding: 20px 24px;
    }
    .card-content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      &-item {
        width: 25%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 2;
      }
      .click-btn {
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
    }
  }
  .invoice-view-content {
    height: calc(100% - 166px);
    margin-top: 20px;
    background: #ffffff;
    border-radius: 2px;
    padding: 20px;
  }
}
</style>
