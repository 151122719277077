export const typeOptions = [
    { label: '发薪开票', value: '发薪开票' },
    { label: '取票开票', value: '取票开票' },
    { label: '会费开票', value: '会费开票' }
    // { label: '保险', value: '2' }
]

export const statusOptions = [
    { label: '已开票', value: '1' },
    { label: '待开票', value: '0' }
]

export default {
    typeOptions,
    statusOptions
}
