<template>
  <a-modal
    v-model="visible"
    width="1200px"
    title="申请开票"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
    class="add-invoice"
  >
    <div class="add-invoice-content">
      <div class="left">
        <div
          v-for="item in typeOptions"
          :key="item.value"
          @click="handleChangeActive(item.value)"
          :class="{
            'type-item': true,
            'type-item-active': active == item.value,
          }"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="right">
        <p-table
          ref="table"
          row-key="id"
          :rowSelection="rowSelection"
          extraHeight="500"
          :sourceData="getDataApi"
          :columns="columns"
        >
        </p-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { typeOptions } from './optionType'
import moment from 'moment'
import { getFX, getHY, add } from '@/api/invoice'
import { typeOptions as salaryTypes } from '@/components/user-salary/utils'

export default {
  props: {
    invoiceHeaderId: String
  },
  data () {
    return {
      confirmLoading: false,
      visible: false,
      typeOptions,
      selectedRowKeys: [],
      active: '发薪开票'
    }
  },
  computed: {
    rowSelection () {
      const { selectedRowKeys } = this
      return {
        columnWidth: '60',
        selectedRowKeys,
        onChange: this.handleTableSelectChange
      }
    },
    columns () {
      const column =
        this.active != '会费开票'
          ? [
              {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
                width: 300,
                ellipsis: true
              },
              {
                title: '发薪类型',
                dataIndex: 'flag',
                key: 'flag',
                customRender: (t, r) => {
                  const type = salaryTypes.find((item) => item.value == t)?.label
                  return type
                }
              },
              {
                title: '最后申请发薪时间',
                dataIndex: 'applyTime',
                key: 'applyTime',
                customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss')
              },
              {
                title: '申请结算时间',
                dataIndex: 'closeTime',
                key: 'closeTime',
                customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss')
              }
            ]
          : [
              {
                title: '订单编号',
                dataIndex: 'orderNumber',
                key: 'orderNumber'
              },
              {
                title: '产品名称',
                dataIndex: 'productName',
                key: 'productName'
              },
              {
                title: '金额（￥）',
                dataIndex: 'price',
                key: 'price'
              },
              {
                title: '订单交易时间',
                dataIndex: 'createTime',
                key: 'createTime',
                customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss')
              }
            ]
      return [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        ...column
      ]
    }
  },
  methods: {
    show () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    },
    getDataApi (params) {
      if (this.active !== '会费开票') {
        return getFX({
          ...params,
          // status: 1,
          taxStatus: this.active === '发薪开票' ? 1 : 0,
          enterpriseId: this.$store.getters.enterpriseId
        })
      } else {
        return getHY({
          ...params,
          enterpriseName: this.$store.getters.userInfo.enterpriseInfoDto?.enterpriseName
        })
      }
    },
    handleChangeActive (value) {
      this.active = value
      this.selectedRowKeys = []
      this.$refs.table.refresh()
    },
    handleTableSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleSubmit () {
      if (!this.selectedRowKeys.length) {
        this.$message.error('请勾选需要开取发票的订单')
      } else {
        this.confirmLoading = true
        add({
          ids: this.selectedRowKeys,
          type: this.active,
          invoiceHeaderId: this.invoiceHeaderId
        }).then(res => {
          this.$message.success('申请开票成功，请耐心等待工作人员进行开票')
          this.$emit('success')
          this.handleCancel()
        }).finally(() => {
          this.confirmLoading = false
        })
      }
    },
    handleCancel () {
      this.confirmLoading = false
      this.visible = false
      this.active = '发薪开票'
      this.selectedRowKeys = []
    }
  }
}
</script>

<style lang="less">
.add-invoice {
  &-content {
    display: flex;
    > .left {
      padding: 12px;
      margin-right: 20px;
      background: #eeeeee;
      .type-item {
        width: 160px;
        height: 60px;
        background: #ffffff;
        text-align: center;
        line-height: 60px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        border: 1px solid #ffffff;
        cursor: pointer;
        position: relative;
        &-active {
          color: #1890ff;
          border: 1px solid #1890ff;
          &::after {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 0;
            top: 0;
            background: url('../../assets/check.png');
          }
        }
      }
    }
  }
}
</style>
